import { merge, Theme } from "theme-ui"
import { base } from "@theme-ui/preset-base"

const makeTheme = <T extends Theme>(t: T) => t

const theme = makeTheme(
  merge(base, {
    colors: {
      primary: "#e07a5f",
      gray: "#666",
      light: "#f7f7f7",
    },
    fonts: {
      body: "'Inter', system-ui, sans-serif",
      heading: "'Inter', system-ui, sans-serif",
      monospace: "Menlo, monospace",
    },
    fontWeights: {
      body: 400,
      heading: 700,
      bold: 700,
    },
    lineHeights: {
      body: 1.5,
      heading: 1.125,
    },
    fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 72],
    space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
    sizes: {
      container: 1000,
    },
    links: {
      simple: {
        color: "text",
        textDecoration: "none",

        "&:hover": {
          color: "primary",
        },
      },
    },

    styles: {
      root: {
        lineHeight: "body",
        color: "text",
        backgroundColor: `background`,
        margin: 0,
        padding: 0,
        boxSizing: `border-box`,
        textRendering: `optimizeLegibility`,
        WebkitFontSmoothing: `antialiased`,
        MozOsxFontSmoothing: `grayscale`,
      },
      a: {
        color: "primary",
      },

      hr: {
        borderTop: "1px solid #e7e7e7",

        ".dotted": {
          display: "none",
        },
      },
      header: {
        display: "flex",
        alignItems: "center",
        mt: [3, 4],
        mb: [3, 4],
        pb: [3, 4],
        fontSize: [18, 22, 22],
        borderBottom: "1px solid #f7f7f7",

        title: {
          fontSize: [18, 22, 22],
          fontWeight: 700,
          mr: [0, 4],
          textDecoration: "none",
          color: "text",
        },

        caption: {
          fontSize: 13,
          color: "gray",
        },

        link: {
          display: ["flex", "block"],
          flexDirection: "row",
          fontSize: [13, 14],
          fontWeight: "bold",
          textTransform: "uppercase",
          textDecoration: "none",
          mx: [1, 2],
          color: "#666",

          "&:hover": {
            color: "primary",
          },
        },
      },
      postListing: {
        m: 0,
        p: 0,

        post: {
          mb: 4,
        },

        title: {
          textDecoration: "none",
        },
      },
      post: {
        lineHeight: "body",
      },
    },
  })
)

export default theme
